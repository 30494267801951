<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 13" fill="none">
  <path d="M1.90476 0C0.854476 0 0 0.820967 0 1.83007V8.78431C0 9.79341 0.854476 10.6144 1.90476 10.6144H12.1905C13.2408 10.6144 14.0952 9.79341 14.0952 8.78431V1.83007C14.0952 0.820967 13.2408 0 12.1905 0H1.90476ZM14.8571 1.98519V8.78431C14.8571 10.1993 13.6632 11.3464 12.1905 11.3464H2.06622C2.36108 11.9917 3.03048 12.4444 3.80952 12.4444H12.1905C14.2945 12.4444 16 10.8058 16 8.78431V3.66013C16 2.91163 15.5288 2.26849 14.8571 1.98519ZM5.90253 3.29412C6.00096 3.29375 6.09967 3.31764 6.18824 3.36632L8.85491 4.83037C9.03282 4.92846 9.14286 5.11027 9.14286 5.30719C9.14286 5.5041 9.03282 5.68592 8.85491 5.78401L6.18824 7.24806C6.10063 7.29637 6.00267 7.32026 5.90476 7.32026C5.80571 7.32026 5.70632 7.29604 5.61756 7.24663C5.44156 7.14817 5.33333 6.96706 5.33333 6.77124V3.84314C5.33333 3.64732 5.44156 3.46621 5.61756 3.36775C5.70556 3.3187 5.8041 3.29448 5.90253 3.29412Z"
  :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 13
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
